@import (reference) "ftms.less";

// Page header
// -------------------------

.page-header {
    margin-top: 20px;
    padding-bottom: 0;
    border-bottom: 1px solid @dupont-gray-light;

    button {
        margin-top: -10px;
    }
}


// Text color
// -------------------------

li.danger,
span.danger,
div.danger,
p.danger,
i.danger {
    color: @brand-danger;
}


h5 {
    margin-bottom: 5px;
}

.dim-text {
    color: @gray-light;
}