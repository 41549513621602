@import (reference) "ftms.less";
@import (reference) "_utility.less";

.detail-content {
    &:not(.rollup-edit) {
        margin-top: 47px;
    }

    .loading-container {
        width: 100%;
        text-align: center;
        font-size: 18px;
        margin-top: 20px;
        height: 100%;
        position: absolute;
        .background-color(white, .8);
        top: -20px;
        padding-bottom: 20px;
        padding-top: 20px;
        margin-left: -15px;
        z-index: 10;

        .affix {
            width: 100%;
            left: 0;
            text-align: center;
        }
    }

    .not-found-container {
        width: 100%;
        text-align: center;
        font-size: 18px;
        margin-top: 20px;
        height: 100%;
        position: absolute;
        top: -20px;
        padding-bottom: 20px;
        padding-top: 20px;
        margin-left: -15px;

        i {
            color: @brand-danger;
        }

        p {
            font-size: 15px;
            margin-top: 15px;
        }
    }

    .detail-content-header {
        margin-bottom: 5px;
        h3 {
            margin-top: 10px;
        }
        .pull-right button {
            margin-top: 5px;
        }
    }
}
