@import (reference) "ftms.less";

input[type=checkbox] {
    vertical-align: -5%;
}

select.form-control {
    padding: 5px 7px;
}

.form-control-file {
    display: block;
    margin-right: -15px;
    margin-bottom: 0;

    input {
        display: none;
    }

    div {
        padding-right: 0;

        .form-control {
            height: auto;
            font-weight: normal;
        }
    }
}

.form-spacer {
    display: block;
    width: 100%;
    height: @input-height-base;
    margin: 5px 0;
}

.addOnSelect {
    border: 1px solid #ccc;
    background-color: #fff;
    color: #555555;
    font-size: 14px;
    line-height: 1.42857143;
    height: 32px;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.input-group-addon {
    &:not(:first-child) {
        border-left: 0;
    }

    &:not(:last-child) {
        border-right: 0;
    }
}

.addon-button {
    background-color: white;

    &:hover {
        cursor: pointer;
    }

    &:active {
        background-color: #e6e6e6;
        border-color: #adadad;
        color: #333;
    }
}

.divTable {
    width: 100%;
    display: table;

    div {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding: 0 5px;
    }

    div:first-child {
        width: 35%;
    }

    div:first-child, div:nth-child(2), div:nth-child(3), div:nth-child(4) {
        border-right: 1px solid;
        border-color: @input-border;
    }
}

.divTableBorder {
    table-layout: fixed;

    div:nth-child(n + 4) {
        border-right: 1px solid;
        border-color: @input-border;
    }

    div:last-child {
        border-right: none;
    }
}

.field-or {
    position: absolute;
    font-size: @font-size-small;
    @media(min-width: @screen-md-min) {
        top: 29%;
        left: 50%;
    }
    @media(max-width: @screen-sm-max) {
        top: 35%;
        left: 27%;
    }
    @media(max-width: @screen-xs-max) {
        top: 49%;
        left: 47%;
    }
}
