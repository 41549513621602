@import (reference) "../ftms.less";

.arm-upload-component {
    .empty-results-message {
        text-align: center;
        margin-top: 75px;
    }
    .file-drag-drop-container {
        border: 2px dashed black;
        min-height: 100px;

        .file-drag-drop-header {
            display: block;
            text-align: center;
            font-size: 1.5em;
        }

        .file-drag-drop-subheader {
            display: block;
            text-align: center;
        }

        span:first-child {
            margin-top: 20px;
        }

        span:last-child {
            margin-bottom: 20px;
        }
    }

    .file-list-container {
        background-color: white;
        overflow: auto;

        // Escape the calc expression so it isn't evaluated by less
        max-height: ~"calc(100vh - 435px)";
        min-height: 350px;

        .file {
            border: 1px solid #c7c7c7;
            padding: 5px 7px;

            h4 {
                margin-top: 0;
            }
        }
    }
}
