@media (min-width: @screen-md-min) {
  .modal-lg { width: @modal-lg; }
  .modal-xlg {width: @modal-xlg;}
}


.modal-body {
    max-height: ~"calc(100vh - 210px)";
    overflow-y: auto;
}

.modal-footer div {
    text-align: left;
}

.modal-footer .form-group .input-group .input-group-btn {
  .btn + .btn {
    margin-left: -1px;
  }
}