@import (reference) "ftms.less";
@import (reference) "_utility.less";

.alert-custom-search {
    margin-bottom: 0;
}

.search-controls-container {
    > div {
        margin-bottom: 10px;
    }
}

.search-table-status-container {
    margin-bottom: 5px;

    .left-container {
        > * {
            float: left;
            margin-right: 5px;
        }
    }

    a {
        cursor: pointer;
    }

    .center-container {
        text-align: center;
    }

    .right-container {
        > * {
            margin-left: 5px;
            display: inline-block;
        }

        white-space: nowrap;
        text-align: right;
    }

    .selected-count-indicator {
        min-width: 110px;
        text-align: right;
    }

    .next-button {
        margin-left: 10px;
    }

    .previous-button {
        margin-right: 10px;
    }

    .ellipsis-indicator {
        color: #AAA;
        margin-left: 3px;
        margin-right: 3px;
        margin-top: 3px;
    }
}

.full-screen-loading .table-overlay .loading-container {
    position: fixed;
}

.table-overlay {
    position: absolute;
    .background-color(white, .8);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 115;

    .loading-container {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 30px;
        font-size: 18px;

        > i {
            font-size: 18px;
        }
    }
}

.overlay-container {
    position: relative;
}

.search-table-container {
    position: relative;
    margin-bottom: 10px !important;
    overflow-x: auto;
    overflow-y: auto;

    .search-table {
        overflow: hidden;

        .check-column {
            width: 85px;
            text-align: center;
            vertical-align: middle;
        }

        .check-column2 {
            width: 40px;
            text-align: center;
            vertical-align: middle;
        }

        * {
            -moz-user-select: none;
            -ms-user-select: none;
            -webkit-user-select: none;
            user-select: none;
        }

        tr {
            cursor: default;

            a {
                cursor: pointer;
                text-decoration: underline;
            }

            > th {
                white-space: nowrap;

                > * {
                    float: left;
                }
            }
        }
    }

    .hand-pointer {
        > tbody {
            > tr {
                > th,
                > td {
                    cursor: pointer;
                }
            }
        }
    }
}

.container-fluid .search-table-container {
    overflow-y: scroll;
}

table.search-table {
    > tbody > tr > td.no-border {
        border: 0;
    }
}

.table-sortable-header {
    cursor: pointer;

    span {
        float: left;

        &.indicator-container > i {
            position: absolute;
            margin-left: 4px;
            margin-top: 2px;
            color: #AAA;
            visibility: hidden;

            &.fa-sort-asc, &.fa-sort-desc {
                color: white;
                visibility: visible;
            }
        }
    }

    &.sort-active {
        background: @dupont-gray-dark;
    }

    &:hover {
        span.indicator-container > i {
            visibility: visible;

            &.fa-sort {
                visibility: visible;
            }
        }
        background: @dupont-gray-dark;
    }
}

.fixed-header-table-scroll-container {
    width: calc(~'100% - 17px');
    overflow: hidden;
    position: absolute;
    z-index: 10;

    .table.fixed-header-table {
        th {
            padding: 5px;
        }
    }
}

.background-filter.btn-default {
    background-color: @state-info-bg;
}