.opacity (@opacity) {
    @opacityPercentage: @opacity * 100;
    opacity: @opacity;
    filter: ~"progid:DXImageTransform.Microsoft.Alpha(Opacity=(@{opacityPercentage}))";
}

.background-color(@color,@alpha) {
    @opacityPercentage: @alpha * 100;
    @rgba: rgba(red(@color),green(@color),blue(@color),@alpha);
    @argb: argb(@rgba);
    background-color: @color;
    background-color: @rgba;
    /*-ms-filter: ~"progid:DXImageTransform.Microsoft.gradient(startColorstr=@{argb}, endColorstr=@{argb})";*/
    filter: ~"progid:DXImageTransform.Microsoft.Alpha(Opacity=@{opacityPercentage})";
}
