// Angular theme
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "~material-design-icons-iconfont/dist/material-design-icons.css";
// Core variables and mixins
@import "bootstrap/variables.less";
@import "bootstrap/mixins.less";

// Custom variables
@import "_variables.less";

// Reset and dependencies
@import "bootstrap/normalize.less";
@import "bootstrap/print.less";
@import "bootstrap/glyphicons.less";

// Core CSS
@import "bootstrap/scaffolding.less";
@import "bootstrap/type.less";
@import "bootstrap/code.less";
@import "bootstrap/grid.less";
@import "bootstrap/tables.less";
@import "bootstrap/forms.less";
@import "bootstrap/buttons.less";

// Components
@import "bootstrap/component-animations.less";
@import "bootstrap/dropdowns.less";
@import "bootstrap/button-groups.less";
@import "bootstrap/input-groups.less";
@import "bootstrap/navs.less";
@import "bootstrap/navbar.less";
@import "bootstrap/breadcrumbs.less";
@import "bootstrap/pagination.less";
@import "bootstrap/pager.less";
@import "bootstrap/labels.less";
@import "bootstrap/badges.less";
@import "bootstrap/jumbotron.less";
@import "bootstrap/thumbnails.less";
@import "bootstrap/alerts.less";
@import "bootstrap/progress-bars.less";
@import "bootstrap/media.less";
@import "bootstrap/list-group.less";
@import "bootstrap/panels.less";
@import "bootstrap/responsive-embed.less";
@import "bootstrap/wells.less";
@import "bootstrap/close.less";

// Components w/ JavaScript
@import "bootstrap/modals.less";
@import "bootstrap/tooltip.less";
@import "bootstrap/popovers.less";
@import "bootstrap/carousel.less";

// Utility classes
@import "bootstrap/utilities.less";
@import "bootstrap/responsive-utilities.less";

// Utility
@import "_utility.less";

// Custom Stylesheets
@import "_base.less";
@import "_grid.less";
@import "_forms.less";
@import "_favorites.less";
@import "_buttons.less";
@import "_common-look-and-feel.less";
@import "_context-menu.less";
@import "_footer.less";
@import "_navbar.less";
@import "_panels.less";
@import "_search-table.less";
@import "_protocol-page.less";
@import "_protocol-detail.less";
@import "_responsibility-report-search.less";
@import "_tables.less";
@import "_upload-page.less";
@import "_type.less";
@import "_bootstrap-extensions.less";
@import "_form-item.less";
@import "_trial-detail.less";
@import "_trial-page.less";
@import "_cards.less";
@import "_IE-specific.less";
@import "_typeahead.less";
@import "_reports.less";
@import "_main-header.less";
@import "_tech-objective-detail.less";
@import "_tech-objective-page.less";
@import "_news-page.less";
@import "_user-page.less";
@import "_fixed-header.less";
@import "_widget.less";
@import "_detail-page.less";
@import "_modals.less";
@import "_specie-search.less";
@import "_placeholder.less";
@import "_search-select.less";
@import "_sop-summary.less";
@import "_svg.less";
// FTMS Site Components
@import "site-components/__all-site-components.less";
@import "_material-design-overrides.less";
@import "_values-editor-nav.less";


body {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    position: absolute;
}
