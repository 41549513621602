document-picker {
  .mat-list-base .mat-list-item {
    .mat-list-item-content {
      padding: 0;
    }

    &.mat-2-line {
      height: 3em;
    }
  }

  mat-list {
    max-height: 200px;
    overflow: auto;
  }

  .drag-and-drop-dragover.prevent-file-drop {
      background-color: #bbb;

    .prevent-file-drop-ui {
      display: flex;
    }
  }

  .prevent-file-drop-ui {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background-color: #fff;
    opacity: 0.9;

    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
}
