@import (reference) "ftms.less";

//
// Tables
// --------------------------------------------------


.table-shadow-container,
.table-shadow-container-nomargin {
    position: relative;
    .box-shadow(0 2px 10px rgba(0,0,0,.25));
    background-color: white;
}

.table-shadow-container {
    margin-bottom: 20px;
}
table :not(.mat-calendar-table),
.table-striped {
    > thead {
        color: white;
        background-color: @dupont-gray;
    }

    > tbody {
        > tr.selected,
        > tr.selected.zebra-row {
            background-color: @table-bg-selected;

            &:hover,
            &.hover {
                background-color: darken(@table-bg-selected, 7%);
            }
        }

        > tr > td {
            word-break: normal;
            overflow-wrap: break-word;
            word-wrap: break-word;
        }

        > tr > td.selected {
            background-color: @table-bg-selected;

            &:hover:not(.hover),
            &.hover-td {
                background-color: darken(@table-bg-selected, 7%);
            }
        }
    }

    a {
        cursor: pointer;
        text-decoration: underline;
    }
}

.table {
    margin-bottom: 0;
}

.table-bordered {
    border: 0;
    > thead {
        > tr {
            > th {
                border: 1px solid @dupont-gray;

                &:first-child {
                    border-left: 0;
                }
                &:last-child {
                    border-right: 0;
                }
            }
        }
    }
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                border: 1px solid @table-border-color;

                &:first-child {
                    border-left: 0;
                }
                &:last-child {
                    border-right: 0;
                }
            }
            &:last-child {
                > th,
                > td {
                    border-bottom: 0;
                }
            }
        }
    }
}

.table-footer {
    padding: 10px 5px;
}

.table-wide {
    table-layout: fixed;
}

.table-hover {
    > tbody > tr.hover,
    > tbody > tr > td.hover:not(.selected) {
        background-color: @table-bg-hover;
    }
}

.form-table {
    background: none;

    > thead {
        > tr {
            > th,
            > td {
                border: 0;
            }
        }
    }
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                border: 0;

                button.btn-sm {
                    margin-top: 2px;
                }
            }
        }
    }

    > tfoot > tr {
        background-color: @gray-lighter;

        > th,
        > td {
            font-weight: bold;
        }
    }
}

.edit-table {
    background: @gray-lighter;

    > thead {
        > tr {
            > th,
            > td {
                border: 0;
            }
        }
    }
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                padding: 0;
                border: 1px solid @border-color;

                &:first-child {
                    border-left: 0;
                }
                &:last-child {
                    border-right: 0;
                }

                > input,
                > select,
                span.select2-selection {
                    border-radius: 0;
                    border-color: transparent;
                    box-shadow: none;
                }

                button.btn-sm {
                    margin-top: 2px;
                }
            }
        }
    }
}

tr.zebra-row {
    background-color: @table-bg-accent;
}

.th-darker-color {
    background-color: @dupont-gray-dark;
}

.th-darkest-color {
    background-color: @dupont-gray-darker;
}

.ag-grid-table-border {
    padding: 0;
    border: 1px solid @border-color;
    margin: 0;
}

.mat-row:nth-child(even) {
    background-color: @table-bg-accent;

    &.selected {
        background: @table-bg-selected;
    }

    &:hover {
        background-color: @table-bg-hover;
    }
}

.mat-row:nth-child(odd) {
    background-color: white;

    &.selected {
        background: @table-bg-selected;
    }

    &:hover {
        background-color: @table-bg-hover;
    }
}

.ftms-ag-grid.ag-theme-bootstrap {

    font-family: Gilroy,Arial,Helvetica,sans-serif;
    font-size: @font-size-base;
    font-weight: unset;


    .ag-row-hover {
        /* putting in !important so it overrides the theme's styling as it hovers the row also */
        background-color: @table-bg-hover;
    }

    .ag-row-selected {
        background-color: @table-bg-selected;

        &.ag-row-hover {
            background-color: darken(@table-bg-selected, 7%);
        }
    }

    .ag-header {
        /*color: white;
        background-color: #7b7a7a;*/
        font-weight: bold;
        color: white;
        background-color: @dupont-gray;
    }

    .ag-add-border {
        padding: 4px;
        border: 1px solid #D2C5BF;
        margin: 0;
    }

    .ag-body-viewport {
        overflow-y: scroll !important;
    }

    .ag-filter-icon {
        color: white;
        background-color: #7b7a7a;
        font-weight: bold;
    }

    .ag-hidden {
        color: white;
        background-color: #7b7a7a;
        font-weight: bold;
    }

    .ag-row {
        position: absolute;
    }

    .ag-tooltip {
        color: white;
    }

    .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
    .ag-ltr .ag-cell {
        border-left: none;
        border-top: none;
        border-right: 1px solid #D2C5BF;
        border-bottom: 1px solid #D2C5BF;
    }

    .ag-pinned-left-cols-container {
        border-right: 1px solid #D2C5BF;
    }

    .ag-main-div-bottom-pad {
        padding-bottom: 10px;
    }

    .ag-icon {
        color: white !important;
    }
}
