@import (reference) "bootstrap/variables.less";

.specie-input {
    // change rounded bottom to flat if data
}

.specie-list {
    border: solid; 
    background-color: white; 
    border-width: thin; 
    border-color: lightgray; 
    overflow: auto;
    resize:vertical;
    height:150px;

    li {
        padding: 3px;
        padding-left: 10px;
        padding-right: 10px;
    }

    li:hover {
        background-color: Highlight;
    }
}

.specie-li-text {
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
}

