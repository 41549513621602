@import (reference) "_variables.less";
@import (reference) "bootstrap/variables.less";
@import (reference) "bootstrap/mixins/vendor-prefixes.less";
@import (reference) "_utility.less";
@import (reference) "bootstrap/mixins/gradients.less";

.tech-objective-detail {

    .technical-objectives-detail-content {
        margin-top: 47px;
    }

    .associated-protocols {
        li {
            .associated-protocol-link {
                > span {
                    &.protocol-number {
                        font-weight: bold;
                        /*width: 95px;*/
                    }

                    &.protocol-title {
                        clear: both;
                    }
                }

                &:hover {
                    > span {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .tech-objective-detail-loading-container {
        width: 100%;
        text-align: center;
        font-size: 18px;
        margin-top: 20px;
        height: 100%;
        position: absolute;
        .background-color(white, .8);
        top: -20px;
        padding-bottom: 20px;
        padding-top: 20px;
        margin-left: -15px;
    }

    .tech-objective-not-found-container {
        width: 100%;
        text-align: center;
        font-size: 18px;
        margin-top: 20px;
        height: 100%;
        position: absolute;
        top: -20px;
        padding-bottom: 20px;
        padding-top: 20px;
        margin-left: -15px;

        i {
            color: @brand-danger;
        }

        p {
            font-size: 15px;
            margin-top: 15px;
        }
    }

}

// defined outside .tech-objective-detail because these will
// be attached to the body
.incode-option-container {
    .incode-option {
        float: left;
        display: block;
    }

    .incode-col-1 {
        width: 80px;
    }

    .incode-col-2 {
        width: 60px;
    }

    .incode-spacer {
        width: 25px;
    }

    .incode-col-3 {
        width: 10px;
    }
}