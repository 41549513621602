dl.modal-audit-info.dl-horizontal {
    margin-bottom: 0;


    @media (min-width: 768px) {
        & dt {
            width: 105px;
        }

        & dd {
            margin-left: 120px;
        }
    }
}
