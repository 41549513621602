@import (reference) "ftms.less";

.fix-wrapped-col-sm {    
    @media (min-width: @screen-xs-min) {
        margin-top: -15px;
    }    
    @media (min-width: @screen-sm-min) {
        margin-top: -20px;
    }
    @media (min-width: @screen-md-min) {
        margin-top: 0;
    }
}

.fix-wrapped-col-xs {    
    @media (min-width: @screen-xs-min) {
        margin-top: -15px;
    }    
    @media (min-width: @screen-sm-min) {
        margin-top: 0;
    }
}