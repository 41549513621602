@import (reference) "_variables.less";
@import (reference) "bootstrap/mixins/gradients.less";

//
// Panels
// --------------------------------------------------

// Base class
.panel {
    position: relative;
    border: 0;
    #gradient > .vertical(white; @gray-lightest);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='@{orange-lighter}',GradientType=0 );
}

// Optional heading
.panel-heading {
    padding: 9px 15px 10px 15px;
    border-bottom-width: 1px;
}

.panel-primary > .panel-heading {
    padding: 9px 15px 11px 15px;
    border-bottom-width: 0;
    background-color: @dupont-red;
    color: white;

    .accent-bands {
        position: absolute;
        right: 0;
        top: 0;
        width: 36px;
        height: 37px;
        background: @brand-secondary;
        border-left: 4px solid @dupont-accent6;
        border-right: 28px solid @dupont-accent6;
    }
}

// Optional footer (stays gray in every modifier class)
.panel-footer {
    border-top: 0;
}
