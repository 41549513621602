@import (reference) "ftms.less";
@import (reference) "_utility.less";

#protocol-table-controls-container {
    margin-bottom: 10px;

    .protocol-detail-title-bar > * {
        float: left;
    }

    .back-to-search-results-button {
        margin-right: 20px;
        width: 100%;
    }

    .protocol-title {
        margin-top: 5px;
    }
}

.selected-items-per-page {
    font-weight: bold;
    color: black;
}

.current-page {
    font-weight: bold;
    color: black;
}


.protocol-search-grid {
    .mat-table {
        .mat-cell, .mat-header-cell, .mat-footer-cell {
            padding: 5px;
        }

        .mat-cell:first-of-type, .mat-header-cell:first-of-type, .mat-footer-cell:first-of-type {
            padding-left: 24px;
        }

        .mat-column-ProtocolNumber {
            width: 116px;
        }

        .mat-column-ProtocolTitle {
            width: 100px;
            max-width: 100px;
        }

        .mat-column-Ded {
            max-width: 120px;
        }

        .mat-column-Crops, .mat-column-Pests {
            max-width: 100px;
        }

        tr.mat-header-row {
            height: 25px;
        }

        tr.mat-row {
            height: 25px;
        }
    }
}

.protocol-has-summary-column {
    .yes-summary-underline-color {
        color: lighten(@brand-danger, 30%);
        text-decoration: underline;

        .yes-summary-link {
            color: @brand-danger;
            cursor: pointer;
        }
    }

    .no-summary-underline-color {
        color: lighten(#555, 30%);
        text-decoration: underline;

        .no-summary-link {
            color: #555;
            cursor: pointer;
        }
    }
}

.protocol-general-info-page {
    .protocol-not-found-container {
        width: 100%;
        text-align: center;
        font-size: 18px;
        margin-top: 20px;
        height: 100%;
        position: absolute;
        top: -20px;
        padding-bottom: 20px;
        padding-top: 20px;
        margin-left: -15px;

        i {
            color: @brand-danger;
        }

        p {
            font-size: 15px;
            margin-top: 15px;
        }
    }
}