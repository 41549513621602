
project-grid {
    max-height: 340px;
    overflow: auto;
    display: block;

    table.mat-table {
        border-collapse: separate;
    }

    .mat-header-cell {
        // Not sure why, but the sticky headers don't scroll properly if word wrapping is enabled
        white-space: nowrap;
    }
}
