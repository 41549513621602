@import (reference) "ftms.less";

.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon-button .mat-icon.material-icons {
    font-size: 24px;
}

.mat-spinner {
    margin: 10px auto;
}

mat-card + mat-card {
    margin-top: 20px;
}

mat-form-field {
    width: 100%;
    /* This makes the mat-icon-button background click ripple the same size at the font-size: 24px above in the 1st override */
    .mat-button-ripple, .mat-button-focus-overlay {
        height: 24px;
        width: 24px;
    }
    /* Allow for less white space in mat-chip-lists */
    .mat-chip-input-hide {
        height: 0px;
    }

    .mat-chip-input-display {
        height: 10px;
    }

    button.chip-add-btn {
        margin: 0px 5px 5px 0px;

        .mat-icon {
            margin-top: -1px;
        }
    }

    button.addon-email-btn {
        margin: 0px 5px 5px 5px;

        .mat-icon {
            margin-top: -1px;
        }

        .mat-spinner {
            margin: -5px 0px -5px -5px;
        }
    }
}

.mat-checkbox-section {
    display: flex;
    align-content: center;
    align-items: center;
    height: 60px;
}

.mat-table {
    width: 100%;

    .mat-cell, .mat-header-cell, .mat-footer-cell {
        padding-right: 10px;
    }
}

div, a {
    &.mat-tab-link {
        color: rgba(0,0,0,.87);
        opacity: .9;
    }

    &.mat-tab-label-active {
        background-color: rgba(209, 196, 233, 0.3);
        font-weight: bold;
    }
}

.center-mat-spinner {
    margin: 10px auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.overlay-mat-spinner {
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.286);
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
    justify-content: center;
    align-items: center;
}

.favorite-icon {
    padding-left: 0px;
}

.favorite-icon:hover {
    cursor: pointer;
}

.list-inline.comma-delimit li:after {
    content: ",";
    margin-right: 5px;
}

.list-inline.comma-delimit li:last-child:after {
    content: "";
    margin-right: 0;
}

.tooltip-break {
    white-space: pre-line;
}

.mat-table-bordered {
    .mat-header-cell,
    .mat-cell,
    .mat-footer-cell {
        border-right: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        border-left: none;
        border-top: none;

        &:first-of-type {
            border-left: 1px solid rgba(0, 0, 0, 0.12);
        }
    }

    .mat-header-cell {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
}

.center-angular-text {
    display: flex;
    justify-content: center;
}

.ibp-summary-table {
    .mat-cell, .mat-header-cell, .mat-footer-cell {
        padding: 5px;
    }

    tr.mat-header-row {
        height: 25px;
    }

    tr.mat-header-row > .mat-header-cell:first-child {
        padding-left: 24px;
    }

    tr.mat-header-row > .mat-header-cell:last-child {
        padding-right: 24px;
    }

    tr.mat-row {
        height: 15px;
    }
}

.display-container {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 20px;

    .display-item {
        display: flex;
        align-items: center;
        height: 35px;
    }
}

.mat-form-select-item {
    .mat-form-field {
        padding-top: 5px;
        height: 35px;
        max-width: 200px;

        .mat-form-field-wrapper {
            height: 35px;

            .mat-form-field-infix {
                border-top: none;
            }

            .mat-form-field-underline {
                position: unset;
            }
        }
    }
}

.mat-card .mat-card-header-text {
    margin: 0;
}

.mat-chip.mat-chip-mini {
    margin: 2px;
    padding: 5px;
    line-height: 12px;
    height: auto;
    min-height: 12px;
    font-size: 80%;
}

.mat-menu-chip-menu .mat-menu-content {
    overflow: hidden;
    padding-left: 8px;
    padding-right: 8px;
}

.mat-card-header-has-actions {
    .mat-card-header-text {
        width: 100%;
    }

    .mat-card-title {
        display: flex;
        width: 100%;

        .card-header-text {
            flex: 1;
        }

        .card-header-actions .mat-button,
        .card-header-actions .mat-icon-button {
            margin-top: -6px;
            margin-bottom: -6px;
        }
    }
}

.mat-icon.mat-icon-inline-text {
    vertical-align: top;
    display: inline-flex;
}

/* mat small table */
.mat-table-small tr.mat-header-row {
    height: 22px;
}

.mat-table-small tr.mat-row {
    height: 20px;
}

.mat-table-small div.mat-form-field-infix {
    padding-top: -2px;
    margin-top: 0px;
    border-top: 0px solid transparent;
}

.mat-table-small div.mat-form-field-wrapper {
    padding-bottom: 8px;
}

.mat-table-small div.mat-form-field-underline {
    bottom: 12px;
}

.mat-table-small {
    .grid-button {
        height: 25px;
        min-width: 20px;
        line-height: 22px;
    }
}

.mat-table-medium {
    tr.mat-header-row {
        height: 32px;
    }

    tr.mat-row {
        height: 34px;
    }

    div.mat-form-field-infix {
        padding-top: -2px;
        margin-top: 0px;
        border-top: 0px solid transparent;
    }

    div.mat-form-field-wrapper {
        padding-bottom: 8px;
    }

    div.mat-form-field-underline {
        bottom: 12px;
    }
}

// Lets you simulate a <mat-label> from the <mat-form-field>, but for a <mat-table>
.mat-table-label {
    color: rgba(0, 0, 0, .54);
}

.mat-tab-body-content {
    padding-top: 5px;
    padding-bottom: 5px;

    & > .row {
        margin-left: 0;
        margin-right: 0;
    }
}

.mat-elevation-z8 {
    margin-bottom: 15px;
}

.right-align {
    text-align: right;
}

.button-toggle-primary.mat-button-toggle-checked {
    background: #FFD740;
    width: 100%;

    .mat-button-toggle-label-content {
        color: black;
    }
}

.button-toggle-accent.mat-button-toggle-checked {
    background: #673AB7;
    width: 100%;

    .mat-button-toggle-label-content {
        color: white;
    }
}

.shade-mat-grid-col {
    background-color: lighten(lightgray, 11%);
}

.mat-row.selected .shade-mat-grid-col {
    background-color: darken(@table-bg-selected, 11%);
}

.mat-row:hover .shade-mat-grid-col {
    background-color: darken( @table-bg-hover, 11%);
}

.mat-row.selected .bg-protocol-actual-plan {
    background: @table-bg-selected;
}

.mat-row:hover .bg-protocol-actual-plan {
    background-color: @table-bg-hover;
}

.ui-dialog-message {
    white-space: pre-line;
}

/*This style is for the 'X' (clear text) button inside the single ftms-typeahead component*/
ftms-typeahead {
    display: flex;
    flex-direction: row;

    .typeahead-input {
        justify-content: flex-start;
    }

    .clear-input-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
        justify-content: flex-end;
    }

    .mat-icon {
        margin-top: -5px;
    }
}
