@import (reference) "_variables.less";

.mat-list-base.values-editor-nav-list {
    .mat-list-item {
        &:hover {
            background-color: @corteva-light-blue;
            cursor: pointer;
        }

        height: 30px;
        font-size: small;
    }
}

.values-editor-nav-active {
    background-color: @corteva-gray;
}

.sticky-values-editor-header {
    position: sticky;
    top: 0px;
    background-color: #f9f9f9;
    z-index: 500;
    margin-bottom: 10px;

    button {
        margin-top: 7px;
    }
}