@import (reference) "_variables.less";
@import (reference) "bootstrap/variables.less";
@import (reference) "bootstrap/mixins/vendor-prefixes.less";
@import (reference) "_utility.less";
@import (reference) "bootstrap/mixins/gradients.less";

.protocol-detail {
    margin-top: 50px;


    .table-removelastcol {
        width: 100%;

        > tbody > tr:hover {
            padding: 20px;

            > td {
                &:last-child {
                    visibility: visible;
                }
            }
        }

        > tbody > tr {
            padding: 20px;

            > td {
                &:last-child {
                    visibility: hidden;
                }
            }
        }
    }

    .protocol-locations {
        table.protocol-locations-table {
            background: none;

            th, tr, td, thead {
                border: none;
            }

            th {
                text-align: center;
            }

            .location-divider {
                padding: 0;
                margin-top: 10px;
                margin-bottom: -10px;
                margin-left: 100px;
                margin-right: 100px;
            }
        }
    }

    .protocol-detail-loading-container {
        width: 100%;
        text-align: center;
        font-size: 18px;
        margin-top: 20px;
        height: 100%;
        position: absolute;
        .background-color(white, .8);
        top: -20px;
        padding-bottom: 20px;
        padding-top: 20px;
        margin-left: -15px;

        .affix {
            width: 100%;
            left: 0;
            text-align: center;
        }
    }

    .protocol-not-found-container {
        width: 100%;
        text-align: center;
        font-size: 18px;
        margin-top: 20px;
        height: 100%;
        position: absolute;
        top: -20px;
        padding-bottom: 20px;
        padding-top: 20px;
        margin-left: -15px;

        i {
            color: @brand-danger;
        }

        p {
            font-size: 15px;
            margin-top: 15px;
        }
    }
}

.protocol-detail-table {

    .table {
        background: none;

        th {
            padding-left: 20px;
        }

        td {
            border: none;
            padding-bottom: 0;
        }
    }
}

@media (min-width: @screen-desktop) {
    .col-md-2.timingWidthOverride {
        width: 13.9%;
        text-align: right;
    }

    .col-md-10.timingWidthOverride {
        width: 86.1%;
    }
}
