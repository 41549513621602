
// Create a mixin to easily create CSS toggles based on feature names
.feature-toggle-variant(@featureName) {
    .has-feature_@{featureName} {
        .if-feature_@{featureName} {
            display: block;
        }

        .ifnot-feature_@{featureName} {
            display: none;
        }
    }

    .if-feature_@{featureName} {
        display: none;
    }

    .ifnot-feature_@{featureName} {
        display: block;
    }
}


html {
    .feature-toggle-variant(CortevaLookAndFeel)
}
