.context-menu {
    > ul > li {

        &.disabled {
            color: #AAA;

            .context-menu-icon {
                color: #CCC;
            }

            &:hover {
                .context-menu-icon {
                    color: #CCC;
                }
            }
        }

        .context-menu-icon {
            margin-left: -10px;
            margin-right: 10px;
            color: gray;
        }

        &:hover {
            .context-menu-icon {
                color: black;
            }
        }
    }
}
