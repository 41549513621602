@import (reference) "ftms.less";
@import (reference) "_utility.less";

.btn.disabled,
.btn[disabled] {
    background: #DDD;

    &:hover,
    &:active,
    &:link,
    &:visited {
        background: #DDD;
    }
}

.header-button {
    margin-top: -7px;
    margin-right: 15px;
}

a.content-edit-button {
    margin-top: 2px;
    padding-left: 5px;
    padding-right: 5px;
    text-decoration: none;
    font-size: 13px;
    font-weight: normal;
    color: @link-color;

    > i {
        color: @link-color;
        font-size: 90%;
    }

    &:hover,
    &:active,
    &:link,
    &:visited {
        text-decoration: none;
    }

    &:hover {
        color: @link-hover-color;

        > i {
            color: @link-hover-color;
        }
    }
}

.btn.has-error {
    background-color    : @state-danger-bg;
    z-index    : 2;
}

.show-on-hover {
    visibility: hidden;
}

div:hover > .show-on-hover,
p:hover > .show-on-hover {
    visibility: visible;
}

.btn-blue {
    .button-variant(@blue-dark; @blue-dark; @blue-darker);
    color: white;

    &:active, &:hover, &:focus {
        color: white;
    }
}