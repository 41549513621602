.favorite-star-border {
    color: @favorite-star-border;
}

.favorite-star-fill {
    color: @favorite-star-fill;
}

.non-favorite-star {
    color: @non-favorite-star;
}