@import (reference) "_variables.less";
@import (reference) "_utility.less";
@import (reference) "bootstrap/mixins/vendor-prefixes.less";

.fixed-header {
    position: absolute;
    left: 0;
    z-index: 10;
    width: 100%;
    margin-top: 0;

    &.affix {
        position: fixed;
        top: 0;
        padding: 10px;
        .background-color(white, .85);
        .box-shadow(0 2px 10px rgba(0,0,0,.15));
    }
}

h3.fixed-header-title {
    margin-top: 5px;
    margin-bottom: 8px;
    line-height: 1.2;
}

.sticky-summary-header {
    position: sticky;
    top: 0px;
    background-color: #f9f9f9;
    z-index: 500;
}

.collapsed-container {
    background: none;
    position: sticky;
    top: 10px;
    z-index: 500;
    height: 0px;
    margin-left: 15px;

    .expand-collapse-btn;
}

.expand-collapse-btn {
    &.mat-mini-fab {
        height: 35px;
        width: 35px;
    }

    i {
        display: flex;
    }
}
