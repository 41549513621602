@import (reference) "_variables.less";

.placeholder {
    input, select, button, .select2-selection, .input-group-addon {
        background-color: transparent;  
        border-style: dotted;
    }

    label, i {
        color: @gray-light;
    }
}

button.placeholder {
    background-color: transparent;  
    border-style: dotted;
}