@import (reference) "ftms.less";

svg.tree-chart {
    .box-shadow(0 2px 10px rgba(0,0,0,.25));
    border-radius: 2px;
    background-color: white;

    .node circle {
        cursor: pointer;
        fill: white;
        stroke: #BBB;
        stroke-width: 1px;

        &.children {
            fill: @gray-light;
        }
        &.planned {
            fill: @gray-lighter;
        }
        &.actual {
            fill: white;
        }
        &.used {
            fill: @green-lighter;
        }
    }

    .node rect {
        cursor: pointer;
        &.planned {
            fill: white;
            stroke-width: 1px;
            stroke: @gray-lighter;
        }
        &.actual {
            fill: @gray-lighter;
            stroke-width: 0;
        }
        &.used {
            fill: @green-lighter;
            stroke-width: 0;
        }
        &.outline {
            fill: white;
            stroke-width: 0;
        }
    }

    .node text {
        font-size: 12px;
        cursor: pointer;
        &.children {
            fill: white;
            font-size: 11px;
        }
    }

    path.link {
        fill: none;
        stroke: #BBB;
        stroke-width: 1px;
    }

    body {
        overflow: hidden;
        margin: 0;
        font-size: 14px;
        font-family: "Helvetica Neue", Helvetica;
    }

    #chart, #header, #footer {
        position: absolute;
        top: 0;
    }

    #header, #footer {
        z-index: 1;
        display: block;
        font-size: 36px;
        font-weight: 300;
        text-shadow: 0 1px 0 #fff;
    }

        #header.inverted, #footer.inverted {
            color: #fff;
            text-shadow: 0 1px 4px #000;
        }

    #header {
        top: 80px;
        left: 140px;
        width: 1000px;
    }

    #footer {
        top: 680px;
        right: 140px;
        text-align: right;
    }

    rect {
        fill: none;
        pointer-events: all;
    }

    pre {
        font-size: 18px;
    }

    line {
        stroke: #000;
        stroke-width: 1.5px;
    }

    .string, .regexp {
        color: #f39;
    }

    .keyword {
        color: #00c;
    }

    .comment {
        color: #777;
        font-style: oblique;
    }

    .number {
        color: #369;
    }

    .class, .special {
        color: #1181B8;
    }

    a:link, a:visited {
        color: #000;
        text-decoration: none;
    }

    a:hover {
        color: #666;
    }

    .hint {
        position: absolute;
        right: 0;
        width: 1280px;
        font-size: 12px;
        color: #999;
    }
}