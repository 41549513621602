@import (reference) "../_variables.less";

.ibp-protocol-card-bar-indicator {

    &.bar-wrapper,
    .bar-wrapper{
        display: flex;
    }

    .progress {
        /* remove the default bottom margin on progress bar containers */
        margin-bottom: initial;
    }

    .progress-bar-wrapper {
        display: flex;
    }

    .progress-bar-wrapper > div {
        min-width: 20%;
    }

    .protocol-column-label {
        min-width: 75px;
    }

    .protocol-column:last-of-type {
        margin-right: 0;
    }

    .protocol-column {
        /* space out columns */
        margin-right: 5px;
    }

    .protocol-progress {
        min-width: 100px;
    }

    .protocol-column-progress-bar {
        flex-grow: 1;
        min-width: 100px;
    }

    .progress-display {
        width: 30px;
        height: 20px;
        padding: 0 0 5px 0;
        text-align: center;
    }

    .progress-bar-extra {
        background-color: yellow;
        color: black;
        font-weight: 500;
    }

    .progress-bar-corteva-green {
        background-color: @corteva-corp-green;
    }

    .progress-bar-corteva-orange {
        background-color: @corteva-orange;
    }

    .progress-bar-corteva-corp-blue {
        background-color: @corteva-corp-blue;
    }
}


.mat-card-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin: 0px 20px 20px 20px;

    .mat-card-item {
        margin-top: 20px;
        padding-left: 5px;
        padding-right: 5px;
        flex: 0 1 auto;

        .mat-card-header {
            .mat-card-header-text {
                margin: 0px;
                width: 100%;
            }

            .mat-card-title {
                margin-bottom: 10px;
            }

            .mat-card-subtitle {
                margin-bottom: 8px;
            }
        }

        .mat-card-content {
            margin: 0px;

            .mat-card-content-container {
                display: flex;
                flex-flow: column nowrap;


                .mat-card-content-item {
                    flex: auto;

                    label {
                        text-align: right;
                    }
                }
            }
        }

        .mat-trial-card {
            box-shadow: 0 5px 5px -3px rgba(0,114,206,.2), 0 8px 10px 1px rgba(0,114,206,.14), 0 3px 14px 2px rgba(0,114,206,.12);
            min-width: 250px;

            &:hover {
                background-color: rgba(0, 0, 0, .05);
            }

            .mat-card-title {
                .extIntDisplay {
                    padding-top: 5px;
                    font-size: 14px;
                    color: rgba(0,0,0,.54);
                }
            }
        }

        .mat-non-trial-card {
            min-width: 250px;

            .mat-card-title {
                .active-inactive-display {
                    padding-top: 5px;
                    font-size: 14px;
                    color: rgba(0,0,0,.54);
                }
            }
        }

        .mat-program-project-card:not(.expanded) {
            width: 300px;
        }

        .mat-program-project-card.expanded {
            min-width: 300px;
        }
    }

    :nth-child(even) .mat-protocol-card {
        background: rgba(0,0,0,.025);
    }

    :nth-child(odd) .mat-protocol-card {
        background: rgba(0,0,0,.1);
    }

    :nth-child(even) .mat-non-trial-card {
        background: rgba(0,0,0,.025);
    }

    :nth-child(odd) .mat-non-trial-card {
        background: rgba(0,0,0,.1);
    }

    :nth-child(even) .mat-program-project-card {
        background: rgba(0,0,0,.025);
    }

    :nth-child(odd) .mat-program-project-card {
        background: rgba(0,0,0,.1);
    }
}

.mat-card-item.expanded {
    width: 100%;
    flex: auto;
}