@import (reference) "bootstrap/variables.less";

.form-item {
    /* has to be !important for IE 8 */
    width: 100% !important;

    .form-item-input {
        height: auto;
    }

    [contenteditable='true']:before {
        content: "\feff ";
    }

    .form-item-checkbox {
        margin-top: 12px;
        margin-left: 2px;
    }
}

@media (min-width: @screen-md-min) {
    .form-item {
        &.one-column {
            padding-left: 7.5px;
        }
    }
}

.form-group {
    margin-top: 5px;
    margin-bottom: 5px;
}

.detail-content {
    @media(min-width: @screen-md-min) {
        label.col-form-label {
            width: 20.83333333%;    /* col-md-2_5 */
        }

        .col-form-component {
            width: 79.16666667%;    /* col-md-9_5 */
        }

        .form-horizontal .two-col .form-group,
        .form-horizontal .three-col .form-group {
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;

            label.col-form-label {
                width: 41.66666667%;    /* col-md-5 */
            }
            .col-form-component {
                width: 58.33333333%;    /* col-md-7 */
            }
        }

        .form-horizontal .three-col {
            .col-md-4 > .form-group { 
                label.col-form-label {
                    width: 41.66666667%;    /* col-md-5 */
                }
                .col-form-component {
                    width: 58.33333333%;    /* col-md-7 */
                }
            }
            .col-md-6 > .form-group {
                label.col-form-label {
                    width: 27.7777779%;
                }
                .col-form-component {
                    width: 72.2222221%;
                }
            }
            .col-md-8 > .form-group {
                label.col-form-label {
                    width: 20.8333333%;
                }
                .col-form-component {
                    width: 79.1666667%;
                }
            }
            .col-md-12 > .form-group {
                label.col-form-label {
                    width: 13.8888889%;
                }
                .col-form-component {
                    width: 86.1111111%;
                }
            }
        }
    }

    @media(max-width: @screen-sm-max) {
        .form-horizontal .two-col,
        .form-horizontal .three-col {
            .form-group {
                padding-left: 0;
                padding-right: 0;
            }

            > .form-group:first-child,
            > form-text:first-child > .form-group,
            > .col-md-6:first-child > .form-group,
            > .col-md-4:first-child > .form-group,
            > .col-md-4:nth-child(2) > .form-group {
                margin-bottom: 10px;
            }
        }
    }

    .form-horizontal .two-col,
    .form-horizontal .three-col {
        position: relative;
        padding-left: 0;
        padding-right: 0;
    }

    form-text,
    form-textarea,
    form-select,
    form-select2,
    form-datepicker,
    form-checkbox,
    form-radio,
    form-search-select,
    form-species-search,
    form-uom-group,
    form-select-history,
    form-text-history,
    .form-component {
        margin: 0;
        padding: 0;
    }

    .checkbox-inline {
        padding-bottom: 7px;
    }
}

@media(min-width: @screen-md-min) {
    .zebra-row {
        &:nth-child(2n + 1) {
            background-color: #F7F7F7;
        }
    }

    .detail-content .zebra-row {
        margin: 0 -15px;
        padding: 2px 15px;
        min-height: 46px;
    }
}
