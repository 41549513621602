
.species-search-table {
    .mat-cell, .mat-header-cell, .mat-footer-cell {
        padding: 5px;
    }

    tr.mat-row {
        height: 35px;
    }

    .mat-icon-button {
        width: 20px;
        height: 20px;
        line-height: 20px;
    }
}

.species-selected-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;

    .text-display {
        padding-left: 24px;
    }

    .trash-can {
        width: 30px;
        height: 30px;
        line-height: 30px;
        margin-right: 10px;
    }
}

.species-selected-list .species-selected-list-item-container:nth-child(even) {
    background: #f9f9f9;
    border-top: 1px solid rgba(0,0,0,.12);
}

.species-selected-list-item-container {
    height: 35px;
}


.species-data-table-container-no-header {
    margin-top: 20px;
    width: 100%;
    overflow: auto;
    max-height: 230px;
}
