@import (reference) "../_variables.less";


.primary {
    circle {
        fill: @primary;
    }
}

.warning {
    circle {
        fill: @warning;
    }
}

.danger {
    circle {
        fill: @danger;
    }
}

.info {
    circle {
        fill: @info;
    }
}

.dark {
    circle {
        fill: @dark;
    }
}

.success {
    circle {
        fill: @success;
    }
}


.support-bot-chat-window::-webkit-scrollbar {
    width: 5px;
}

.support-bot-chat-window::-webkit-scrollbar-thumb {
    background: #666;
}

.support-bot-chat-settings::-webkit-scrollbar {
    width: 5px;
}

.support-bot-chat-settings::-webkit-scrollbar-thumb {
    background: #666;
}

.support-bot-container {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 10px;
    z-index: 100;
    background: white;
    box-shadow: 0 0 5px gray;
    border-radius: 5px;
    width: 250px;
}

.support-bot-title-bar {
    display: flex;
    justify-content: flex-end;
    padding: .25rem;
    border-bottom: 1px solid rgb(232, 232, 235);
}

.support-bot-chat-window {
    max-height: 400px;
    overflow-y: auto;
}

.support-bot-chat-settings {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.support-bot-chat-setting {
    display: flex;
}

.support-bot-chat-message-container {
    display: flex;

    &.support-bot {
        justify-content: flex-end;
    }

    &.support-user {
        justify-content: flex-start;
    }

    margin: .5em .25em;
}

.support-bot-chat-title {
    font-weight: bold;
}

.active-color {
    font-size: 1.75em !important;
}

.color-icon {
    cursor: pointer;
    font-size: 1.25em;
}

.support-bot-chat-bubble {
    &.support-bot {
        background-color: #ced4da;
        text-align: right;
    }

    &.support-user {
        text-align: left;
    }

    flex-shrink: 1;
    display: inline-block;
    padding: .25em .5em;
    border-radius: 5px;
    overflow-wrap: anywhere;
    max-width: 200px;
    font-family: Arial, Helvetica, sans-serif;
}

.support-bot-chat-message {
    .support-bot {
    }

    .support-user {
    }
}

.support-bot-input-container {
    padding: .5rem;
    border-top: 1px solid rgb(232, 232, 235);
}

.support-bot-title-close-icon {
    :hover {
        color: @corteva-red;
    }

    cursor: pointer;
}

.support-bot-title-settings-icon {
    :hover {
        color: @corteva-corp-blue;
    }

    cursor: pointer;
}

.support-bot-title-trash-icon {
    :hover {
        color: @corteva-yellow;
    }

    cursor: pointer;
}

.support-bot-title-back-icon {
    :hover {
        color: #495057;
    }

    cursor: pointer;
    margin-right: auto;
}

.chat-bubble-container {
    display: block;
    align-self: flex-end;
    justify-self: flex-end;
    opacity: 1.0 !important;
    cursor: pointer;
}

.mr-10 {
    margin-right: 10px;
}

.m-2 {
    margin: .5rem;
}

.ml-auto, .mx-auto {
    margin-left: auto;
}

.ml-1, .mx-1 {
    margin-left: .25rem;
}

.color-icon[_ngcontent-fqv-c7] {
    cursor: pointer;
    font-size: 1.25em;
}

.bg-primary {
    background-color: @primary;
}

[hidden] {
    display: none !important;
}

.align-self-end {
    align-self: flex-end;
}