.tech-objective-page {
    .approved-filter-container {
        i {
            min-width: 13px;
        }
    }

    .status-option-icon {
        width: 13px;
    }
}