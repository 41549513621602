@import (reference) "bootstrap/variables.less";
@import (reference) "_variables.less";
@import (reference) "_utility.less";

.visible-ltie11, .visible-ltie10, .visible-ltie9, .visible-ltie8 {
    display: none;
}

body {
    &.ltie8 {
        .hidden-ltie8 {
            display: none;
        }

        .visible-ltie8 {
            display: inline;
        }
    }

    &.ltie9 {
        .hidden-ltie9 {
            display: none;
        }

        .visible-ltie9 {
            display: inline;
        }

        .panel, .table-shadow-container {
            /* mimics a drop-shadow for IE 8 */
            outline: solid #DDDDDD;
            outline-width: 2px;
        }

        .table-striped {
            tr {

                &.IE8-zebra-row {
                    background-color: @table-bg-accent;

                    &:hover {
                        background-color: @table-bg-hover;
                    }

                    &.selected {
                        background-color: @table-bg-selected;
                    }
                }
            }
        }

        .trial-detail .trial-detail-toolbar.affix {
            outline: solid #DDDDDD;
            outline-width: 2px;
        }

        @media (min-width: @screen-md-min) {
            .general-info-form .zebra-row.IE8-odd-row {
                background-color: #F7F7F7;
            }
        }

        .modal-backdrop {
            .background-color(black, .5);
        }
    }

    &.ltie10 {
        .hidden-ltie10 {
            display: none;
        }

        .visible-ltie10 {
            display: inline;
        }

        position: absolute;
        min-height: 100%;
        min-width: 100%;
    }

    &.ltie11 {
        .hidden-ltie11 {
            display: none;
        }

        .visible-ltie11 {
            display: inline;
        }
    }
}
