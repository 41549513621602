@import (reference) "ftms.less";

.report-controls-container {
    margin-top: -10px;

    > div {
        margin-bottom: 20px;
    }

    .form-group {
        margin-top: 0;
    }
}

.button-shim {
    height: 25px;
}
