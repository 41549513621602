@import (reference) "ftms.less";

.search-select {
    .search-select-expanded {
        > .input-group-addon,
        > .input-group-btn > button {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .search-select-options-container {
        position: relative;

        ul {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            max-height: 200px;
            overflow-x: hidden;
            overflow-y: auto;
            z-index: 100;
            padding-bottom: 4px;
            border: 1px solid @border-color;
            border-top: 0;
            border-bottom-left-radius: @border-radius-base;
            border-bottom-right-radius: @border-radius-base;
            background-color: white;

            li {
                padding: 5px 10px;

                .search-select-option {
                    float: left;
                }
            }
            li:hover:not(.disabled) {
                background-color: @table-bg-selected;
            }
            li.disabled {
                color: @gray-light;
            }
        }
    }
}