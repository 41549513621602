#planActualToggle {
	margin-left: 10px;
	font: 400 12px Roboto, 'Helvetica Neue', sans-serif;
	height: 30px;
	width: 110px;
	line-height: 20px;
}

#planActualToggle > mat-button-toggle {
	margin-top: -10px;
}

#planActualToggle > mat-button-toggle > span {
	line-height: 20px;
	vertical-align: baseline
}