@import (reference) "_variables.less";

.dashboard {
    .chart-placeholder {
        text-align: center;

        .chart-placeholder-loading {
            margin-top: 20px;
            font-size: 18px;
        }
    }

    .chart-container {
        height: 300px;

        &.my-protocols {
            height: 250px;

            g g g rect {
                cursor:pointer;
            }
        }

        &.protocols-not-Completed {
            height: 250px;
        }

        &.trial-status {
            height: 300px;
        }

        &.rainfall {
            height: 350px;
        }

        &.trial-activity {
        }

        g path, g circle , g g text{
            cursor:pointer;
        }

        svg {
            overflow:visible !important;               
        }
    }

    .chart {
        margin-bottom: 15px;
    }

    .fullscreen-button {
        margin-top: 10px;
    }

    iframe {
        width: 100%;
        height: 800px;
        border: none;
        overflow: hidden;
    }
}

.widget-sub-header {
    button {
        position: relative;
        top: -7px;
    }
}

.widget-body {
    position: relative;
    z-index: 100;
}

.widget-options {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);

    > .detail-content {
        margin-top: 0;
        padding: 5px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    }
}

.widget-overlay {
    position: absolute;
    width: 64%;
    text-align: center;
    vertical-align: middle;
    top: 110px;
    left: 0;
    font-size: @font-size-h1;
}

.widget-overlay-label {
    position: absolute;
    width: 64%;
    text-align: center;
    vertical-align: middle;
    top: 145px;
    left: 0;
    font-size: @font-size-h3;
}
