:root {
  --corteva-corp-blue: #0072CE;
  --corteva-corp-green: #00BF6F;
  --corteva-corp-red: #FC4C02;
  --corteva-dark-red: #CC3602;
  --corteva-orange: #FF8352;
  --corteva-light-orange: #FFAC88;
  --corteva-dark-green: #00B25D;
  --corteva-medium-green: #65FCA2;
  --corteva-light-green: #AEFFCF;
  --corteva-dark-blue: #004ABF;
  --corteva-medium-blue: #459AFF;
  --corteva-light-blue: #88C9FF;
  --corteva-black: #000000;
  --corteva-gray: #E6E8EA;
  --corteva-white: #FFFFFF;
  /*Unofficial colors from support-bot repo*/
  --corteva-yellow: #F3B700;
  --corteva-red: #D33F49;
  --color-hero-dark: #004ABF;
  --corteva-dark: #000000;
  --corteva-neutral: #E6E8EA;
  --corteva-light: #FFFFFF;
  /* Bootstrap Theme Colors --primary: --corteva-corp-blue;*/
  --secondary: --corteva-neutral;
  --success: --corteva-corp-green;
  --info: --color-hero-dark;
  --warning: --corteva-yellow;
  --danger: --corteva-red;
  --light: --corteva-light;
  --dark: --corteva-dark;
}
