@import (reference) "_variables.less";
@import (reference) "bootstrap/variables.less";
@import (reference) "bootstrap/mixins/vendor-prefixes.less";
@import (reference) "_utility.less";
@import (reference) "bootstrap/mixins/gradients.less";

.user-page {
    .country-select-container {
        position: relative;
        width: 100%;
        padding-left: 10px;
        padding-right: 15px;
    }

    .user-page-side-nav {
        &.affix {
            top: 55px;
        }
    }

    .user-page-toolbar {
        position: absolute;
        right: 0;
        margin-top: -40px;

        .btn-group {
            background: white;
            margin-right: 15px;
        }

        #user-page-header {
            display: none;
            margin-top: 5px;
            margin-bottom: 0;
        }

        &.affix {
            position: fixed;
            z-index: 10;
            left: 0;
            top: 0;
            margin-top: 0;
            padding: 10px;
            .background-color(white, .85);
            .box-shadow(0 2px 10px rgba(0,0,0,.15));

            #user-page-header {
                display: block;
            }
        }
    }

    .user-page-loading-container {
        width: 100%;
        text-align: center;
        font-size: 18px;
        margin-top: 20px;
        height: 100%;
        position: absolute;
        .background-color(white, .8);
        top: -20px;
        padding-bottom: 20px;
        padding-top: 20px;
        margin-left: -15px;
    }

    .person-not-found-container {
        width: 100%;
        text-align: center;
        font-size: 18px;
        margin-top: 20px;
        height: 100%;
        position: absolute;
        top: -20px;
        padding-bottom: 20px;
        padding-top: 20px;
        margin-left: -15px;

        i {
            color: @brand-danger;
        }

        p {
            font-size: 15px;
            margin-top: 15px;
        }
    }
}

@media (min-width: @screen-sm-min) {
    .user-page {
        .user-page-side-nav {
            width: 157px;
        }
    }
}

@media (min-width: @screen-md-min) {
    .user-page {
        .user-page-side-nav {
            width: 212px;
        }
    }
}

@media (min-width: @screen-lg-min) {
    .user-page {
        .user-page-side-nav {
            width: 262px;
        }
    }
}
