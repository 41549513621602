

ul.role-list {

    li {
        padding-left: 0;
        padding-right: 2px;

        &:after {
            content: ",";
        }

        &:last-child:after {
            content: "";
        }
    }
}
