.data-table-container {
    width: 100%;
    overflow: auto;

    // Escape the calc expression so it isn't evaluated by less
    max-height: ~"calc(100vh - 435px)";
    min-height: 350px;

    .mat-paginator {
        margin-bottom: 10px;
    }
}
