.scroll-offset-shadow-container {
    transition: box-shadow 0.5s;

    &.scroll-offset-shadow-top-shadow {
        box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.2);
    }

    &.scroll-offset-shadow-bottom-shadow {
        box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.2);
    }

    &.scroll-offset-shadow-bottom-shadow.scroll-offset-shadow-top-shadow {
        box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.2), inset 0 10px 10px -10px rgba(0, 0, 0, 0.2);
    }
}
