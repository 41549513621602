/*@import "variables.css";*/

.CortevaLookAndFeel {
  font-family: Gilroy,Arial,Helvetica,sans-serif;
}
/* Header */
.corteva-header {
  border-bottom: 3px solid var(--corteva-gray);
}

  .corteva-header .corteva-header-content {
    height: 100%;
    background-color: var(--corteva-white);
  }

  .corteva-header .corteva-header-content-wrapper {
    display: flex;
    flex-direction: row;
  }

    .corteva-header .corteva-header-content-wrapper .corteva-heading {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 16px;
      height: 40px;
    }

      .corteva-header .corteva-header-content-wrapper .corteva-heading .corteva-heading-logo {
        height: 100%;
        margin-right: 10px;
        margin-top: 15px;
      }

    .corteva-header .corteva-header-content-wrapper .logo {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
    }

  .corteva-header .corteva-header-content-secondary {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    height: 72px;
    align-items: center;
  }

    .corteva-header .corteva-header-content-secondary #secondary-logo {
      width: 108px;
    }

    .corteva-header .corteva-header-content-secondary .user-info {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      text-align: right;
    }

      .corteva-header .corteva-header-content-secondary .user-info .user-avatar {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: var(--corteva-white);
        border: 2px solid #446fc8;
        height: 74px;
        width: 74px;
        left: 34px;
        margin-left: -24px;
        overflow: hidden;
      }

      .corteva-header .corteva-header-content-secondary .user-info .user-name {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: middle;
        margin-bottom: 10px;
        text-align: right;
        width: 100%;
      }
/* Footer */
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.25);
  display: block;
  left: 0;
  height: auto;
  min-height: 130px;
  background-color: #e6e8ea;
  padding: 25px 25px 0;
  font-family: Gilroy, "Arial Black", sans-serif;
}

  .footer .corteva-logo {
    width: 250px;
  }

  .footer .footer-version {
    text-align: right;
    opacity: .60;
    font-family: inherit;
    font-size: 14px;
  }

  .footer .footer-copyright {
    text-align: center;
    line-height: 26px;
    font-size: 10px;
    margin-top: 10px;
  }
 
/* Navigation */
mat-sidenav-container
{
  flex: 1 1 auto;
}

#page-content-with-footer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#page-content-container
{
  flex-grow: 1;
}

.nav-container {
  display: flex;
  flex-wrap: nowrap;
  background-color: var(--corteva-gray);
  height: 38px;
}

  .nav-container .nav-menu-item {
    background-color: var(--corteva-gray);
    height: 35px;
    margin: 0px;
    cursor: default;
    position: relative;
  }

    .nav-container .nav-menu-item:after {
      position: absolute;
      display: block;
      content: '\7C';
      font-size: 24px;
      font-weight: bold;
      color: darkgray;
      top: -3px;
      right: -10px;
      padding: 0px 10px 0px 10px;
    }

    .nav-container .nav-menu-item:last-child:after {
      content: '';
    }

    .nav-container .nav-menu-item:hover .dropdown-menu {
      max-height: 9999px;
      display: block;
    }

    .nav-container .nav-menu-item:hover .nav-menu-item-label {
      background-color: var(--corteva-white);
    }

    .nav-container .nav-menu-item .nav-menu-item-label {
      display: flex;
      flex-wrap: nowrap;
      color: var(--corteva-corp-blue);
      background: var(--corteva-gray);
      padding: 8px 25px 0px 25px;
      height: 35px;
      text-align: center;
      font-size: 16px;
      vertical-align: text-top;
      font-weight: bold;
      box-sizing: border-box;
      text-decoration: none;
    }

    .nav-container .nav-menu-item .dropdown-menu {
      max-height: 0;
      overflow: hidden;
      background: var(--corteva-gray);
      top: calc(95%);
    }

      .nav-container .nav-menu-item .dropdown-menu li {
        padding: 0;
      }

        .nav-container .nav-menu-item .dropdown-menu li a {
          display: block;
          color: var(--corteva-corp-blue);
          background: var(--corteva-gray);
          padding: 10px 10px;
          text-decoration: none;
        }

          .nav-container .nav-menu-item .dropdown-menu li a:hover {
            background-color: var(--corteva-white);
            text-decoration: none;
          }

.menu-icon {
  font-size: 16px;
  color: var(--corteva-corp-blue);
  background-color: var(--corteva-white);
  margin: 0px 5px 0px 5px;
}
