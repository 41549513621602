@import (reference) "_variables.less";

.corteva-header {
    border-bottom: 3px solid #e6e8ea;

    .corteva-header-content {
        height: 100%;
        background-color: #fff;
    }

    .corteva-header-content-wrapper {
        display: flex;
        flex-direction: row;

        .corteva-heading {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 16px;
            height: 40px;


            .corteva-heading-logo {
                height: 100%;
                margin-right: 10px;
                margin-top: 15px;
            }
        }

        .logo {
            margin: 0;
            padding: 0;
            border: 0;
            font-size: 100%;
            font: inherit;
            vertical-align: baseline;
        }
    }

    .corteva-header-content-secondary {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        height: 72px;
        align-items: center;

        #secondary-logo {
            width: 108px;
        }

        .user-info {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            text-align: right;

            .user-avatar {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: #fff;
                border: 2px solid #446fc8;
                height: 74px;
                width: 74px;
                left: 34px;
                margin-left: -24px;
                overflow: hidden;
            }

            .user-name {
                margin: 0;
                padding: 0;
                border: 0;
                font-size: 100%;
                font: inherit;
                vertical-align: middle;
                margin-bottom: 10px;
                text-align: right;
                width: 100%;
            }
        }
    }
}
