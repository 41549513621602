@import (reference) "../ftms.less";

.login-box-wrapper {
    box-shadow: 3px 2px 13px 1px rgba(0, 0, 0, 0.5);
    margin-top: 25px;
    padding: @padding-large-vertical @padding-large-horizontal;

    h1 {
        color: @gray-lighter;
    }

    .header {
        background-color: @corteva-corp-blue;
        margin-top: -@padding-large-vertical;
        margin-left: -@padding-large-horizontal;
        margin-right: -@padding-large-horizontal;
        padding: @padding-large-vertical @padding-large-horizontal;
    }

    .body {
        margin-bottom: -@padding-large-vertical;
        margin-left: -@padding-large-horizontal;
        margin-right: -@padding-large-horizontal;
        padding: @padding-large-vertical @padding-large-horizontal;
        border-left: 1px solid @panel-default-border;
        border-right: 1px solid @panel-default-border;
        border-bottom: 1px solid @panel-default-border;
    }
}