@import (reference) "_variables.less";
@import (reference) "bootstrap/variables.less";
@import (reference) "bootstrap/mixins/gradients.less";

//
// Navbars
// --------------------------------------------------
.main-nav-container {
    padding: 0;
    background: @navbar-default-bg;

    .above-nav {
        position: relative;
        z-index: 160;
    }
}

.navbar {
    border: 0;
    margin-bottom: 0;
    font-size: 1.2em;
    background: none;

    .navbar-nav {
        > li > a {
            border-left: 0 dotted @dupont-gray;
            border-right: 0 dotted @dupont-gray;

            @media (min-width: @screen-sm-min) {
                margin-right: -1px;
            }

            &:hover, &:focus {
                border-left: 0 solid @dupont-gray;
                border-right: 0 solid @dupont-gray;
            }

            &:hover {
                color: @navbar-default-link-hover-color;
                background-color: @navbar-default-link-hover-bg;
            }

            &:focus {
                background-color: transparent;
                color: @navbar-default-link-color;
            }

            &:active {
                color: @navbar-default-link-hover-color;
                background-color: @navbar-default-link-hover-bg;
            }
        }

        > li.active > a {
            &, &:hover, &:focus {
                color: @navbar-default-link-hover-color;
                background-color: @navbar-default-link-hover-bg;
                border-left: 0 solid @dupont-gray;
                border-right: 0 solid @dupont-gray;
            }
        }

        .dropdown-header {
            color: #777;
        }

        @media (min-width: @screen-sm-min) {
            .dropdown-menu {
                color: @navbar-default-link-color;
                background: @dupont-gray;
                border: 0;
                border-radius: 0;

                > li > a {
                    color: @navbar-default-link-color;
                }

                > li > a:hover, > li > a:focus {
                    background-color: darken(@dupont-gray, 2.5%);
                }
            }
        }

        @media (max-width: @screen-xs-max) {
            .dropdown-header {
                color: #ccc;
            }
        }
    }
}

.navbar-collapse {
    background: none;
    border: none;
}

.orange-crops-image {
    position: absolute;
    right: 0;
    z-index: 5;
    margin-top: -55px;
}

.navbar-shadow {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 40px;
    z-index: 140;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
}

.side-nav {
    position: absolute;
    margin-top: 50px; // set to 50px for on the trial page, if the trial number wraps in the affix
    #gradient > .vertical(white; @gray-lightest);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    color: white;

    @media (min-width: @screen-sm-min) {
                width: 157px;
    }

    @media (min-width: @screen-md-min) {
                width: 212px;
    }

    @media (min-width: @screen-lg-min) {
                width: 262px;
    }

    li {
        cursor: pointer;

        &.disabled {
            font-style: italic;

            > * {
                cursor: default !important;
            }

            &:hover {
                background: none;
                cursor: default;
            }
        }

        a {
            color: @text-color;
            border-top: 0 solid transparent;
            border-bottom: 0 solid transparent;

            &.active, &.active:hover, &:hover {
                background-color: @dupont-red;
                color: white;
            }
            &.active, &.active:hover {
                font-weight: bold;
            }
        }
    }
}

.main-menu-button {
    height: 40px;
    padding: 8px 16px 8px 16px;
    color: @dupont-gray-light;
    font-size: 1.2em;

    &:hover {
        color: white;
        cursor: pointer;
    }
}

.main-menu {
    position: absolute;
    width: 100%;
    z-index: 120;
    padding: 8px 0;
    background-color: @menu-background;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    .container {
        .nav {
            margin-top: 5px;
            margin-bottom: 5px;
            padding: 0;
            > li { 
                > a {
                    padding: 5px 10px 5px 20px;
                    color: white;
                    font-size: 1.1em;

                    &:hover {
                        background-color: @menu-hover;
                    }
                    &:active, &:focus, &.selected {
                        background-color: @menu-highlight;
                    }
                }
            }
            > li + li {
                margin-top: 5px;
            }
        }
        .menu-left {
            padding: 0 0 0 5px;
            .nav {
                margin-top: 0;
                margin-bottom: 0;
                 > li {
                     margin: 0;
                     > a {
                        padding: 10px 10px 10px 10px;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                     } 
                 }
            }
        }
        .menu-center {
            min-height: 205px;
            padding: 0 5px;
            background-color: @menu-foreground;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            .nav > li > a {                
                &:hover {
                    background-color: @menu-hover-invert;
                }
                &:active, &:focus, &.selected {
                    background-color: @menu-highlight-invert;
                }
            }
        }

        &.visible-xs {
            .nav {
                margin: 0;
                > li > a {
                    padding-left: 30px;
                }
            }
            .menu-center {
                min-height: 0;
                padding: 5px;
                border-radius: 0;
                > li > a {
                    padding-left: 50px;
                }
            }
            hr {
                margin: 10px 0;
            }
            .menu-right {
                padding: 0 5px;
                > li > a {
                    padding-left: 25px;
                }
            }
        }
    }
}

.sub-nav-tabs {
    margin: -20px 0 10px -10px;
    padding: 8px 0 0 10px;
    background-color: @dupont-gray;
    box-shadow: inset 0 2px 10px 0 rgba(0, 0, 0, 0.25);

    h3 {
        margin-top: 4px;
        margin-bottom: 0;
        margin-left: 15px;
        color: white;
    }

    h4 {
        margin-top: 8px;
        margin-bottom: 0;
        margin-left: 15px;
        color: white;
    }

    .nav-tabs {
        > li > a {
            padding-top: 6px;
            padding-bottom: 6px;
            border: none;
            color: white;
        }

        > li > a:hover {
            color: @text-color;
        }

        > li.active > a,
        > li.active > a:hover {
            border: none;
            color: @text-color;
            background-color: white;
        }
    }
}

.values-editor-nav-btn {
    background-color: #459AFF;
}

.values-editor-nav-container {
    .dropdown-menu {
        font-size: 13px;

        > .active > a {
            background-color: #459AFF;
        }
    }
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
    display: block;
}

.dropdown-submenu > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
    border-left-color: #fff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
    font-size: 12px;
}
