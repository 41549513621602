.sticky-contractor-search {
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 500;
    box-shadow: 0 8px 6px -8px black;
    padding-bottom: 5px;
}

.address-wrapper {
    display: flex;
    flex-wrap: wrap;

    > div {
        flex-basis: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
    }
}

.contractor-selected-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;

    .text-display {
        padding-left: 24px;
    }

    .trash-can {
        width: 30px;
        height: 30px;
        line-height: 30px;
        margin-right: 10px;
    }
}

.contractor-selected-list .contractor-selected-list-item-container:nth-child(even) {
    background: #f9f9f9;
    border-top: 1px solid rgba(0,0,0,.12);
}

.contractor-selected-list-item-container {
    height: 35px;
}

.contractor-card-header {
    .mat-icon-button {
        margin-top: -10px;
        margin-bottom: -5px;
    }
}

.contractor-table {
    .contractor-input {
        .mat-form-field-wrapper {
            .mat-form-field-subscript-wrapper,
            .mat-form-field-ripple {
                .mat-form-field-hint-wrapper {
                    .mat-hint {
                        span {
                            display: block;
                        }
                    }
                    display: inline-table;
                }
            }
        }
    }

    .mat-form-field {
        height: 70px;
    }
}
