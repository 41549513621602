/* You can add global styles to this file, and also import other style files */
@import "variables.css";
@import "common-look-and-feel.css";

body {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  font-size: 14px;
  line-height: 1.42857143;
  color: #474747;
  background-color: #fff;
  background-image: linear-gradient( 180deg,#fff 0,#f9f9f9);
  background-repeat: repeat-x;
  background-position-y: 91px;
  padding: 0 !important;
  margin: 0;
  padding-bottom: 130px;
  box-sizing: border-box;
}

app-root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

/*header styles*/
.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}

  .list-inline li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
