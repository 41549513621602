@import (reference) "ftms.less";

.upload-page {
    .fileBrowse {
        position: relative;
        overflow: hidden;
        margin-bottom: 10px;
        margin-left: 0;
        cursor: pointer;

        input.upload {
            position: fixed;
            top: -1000px;
            right: 0;
            margin: 0;
            padding: 0;
            font-size: 20px;
            cursor: pointer;
            .opacity(0);
        }
    }
}

#upload-progress-container {
    margin-bottom: -10px;
    position: relative;

    #upload-progress-overlay {
        position: absolute;
        background: rgba(255,255,255,.8);
        width: 100%;
        height: 100%;
        top: 0;
        .opacity(0);
        /*-moz-transition: opacity .30s linear;
        -o-transition: opacity .30s linear;
        -webkit-transition: opacity .30s linear;
        transition: opacity .30s linear;*/
        &.fade-in {
            .opacity(1);
        }

        .loading-container {
            width: 100%;
            text-align: center;
            position: absolute;
            top: 50%;
            font-size: 18px;
            margin-top: -14px;

            > i {
                font-size: 18px;
            }
        }
    }
}
