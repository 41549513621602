// Since we have no easy way to use style encapsulation, this'll do
.assignments-page-table {
    .expand-col,
    .expand-col.trial-col,
    .expand-col.request-col,
    .trial-col,
    .request-col {
        display: none;
    }

    .show-expanded .expand-col {
        display: table-cell;
    }

    .show-trial-cols .trial-col {
        display: table-cell;
    }

    .show-requests-cols .request-col {
        display: table-cell;
    }

    .show-expanded .expand-col.trial-col,
    .show-expanded .expand-col.request-col,
    .show-trial-cols .expand-col.trial-col,
    .show-requests-cols .expand-col.request-col {
        display: none;
    }

    .show-expanded.show-trial-cols .expand-col.trial-col,
    .show-expanded.show-requests-cols .expand-col.request-col {
        display: table-cell;
    }

    .person-name-col {
        width: 180px;
    }
}
