@import (reference) "_variables.less";
@import (reference) "bootstrap/variables.less";

// these styles allow for the container to placehold the space on the page
// while the component inside is being rendered by Knockout.
// Without this, the page jumps when the component is loaded on page refresh.
.main-header-container {
    position: relative;
    display: block;
    min-height: 40px;
}

.main-header {
    background-color: @navbar-default-bg;

    .home-link {
        height: 40px;
        padding: 9px 33px 10px 0;
        background: @navbar-default-brand-hover-bg;
    }

    .home-link, .user-link {
        color: @text-color;
        text-decoration: none;
        position: relative;
        z-index: 10;

        &.small-link {
            display: none;
        }
    }

    a.user-link {
        height: 40px;
        padding: 8px 0 8px 8px;
        color: @dupont-gray-light;
        font-size: 1.2em;

        &:hover {
            color: white;
            text-decoration: none;
        }
    }

    .upload-link {
        height: 40px;
        padding: 10px 8px 10px 8px;
        color: @dupont-gray-light;

        &:hover {
            color: white;
            cursor: pointer;
        }
    }

    .brand-fill {
        width: 33px;
        height: 40px;
        background: @brand-primary;
    }

    .accent-bands {
        width: 36px;
        height: 40px;
        background: @dupont-accent4;
        border-left: 4px solid @dupont-accent6;
        border-right: 28px solid @dupont-accent6;
    }

    .page-title {
        height: 40px;
        padding: 8px 16px;
        color: @dupont-gray-light;
        font-size: 1.2em;
    }

    .release-status {
        height: 40px;
        padding: 8px 16px;
        color: @dupont-gray-light;
        font-size: 1.2em;
    }

    .environment-indicator {
        color: #FFFBEA;
        font-size: 15px;
        border: 2px solid @dupont-gray-dark;
        margin: 7px 10px 8px 0;
        padding: 0 5px 0 5px;

        &.local {
            background: @dupont-gray;
        }

        &.dev {
            background: @brand-success;
        }

        &.qa {
            background: #337ab7;
        }

        &.demo {
            background: @brand-danger;
        }

        &:hover {
            cursor: default;
        }
    }
}

@media (max-width: @screen-sm-max) {
    .main-header {
        .user-link {
            &.small-link {
                display: inline;
            }

            &.normal-link {
                display: none;
            }
        }
    }
}

.brand-background {
    position: absolute;
    left: 0;
    width: 50%;
    height: 40px;
    background: @brand-primary;
}
