@import (reference) "_variables.less";
@import (reference) "bootstrap/variables.less";
@import (reference) "bootstrap/mixins/vendor-prefixes.less";
@import (reference) "_utility.less";
@import (reference) "bootstrap/mixins/gradients.less";

.trial-detail {

    .trial-detail-loading-container {
        width: 100%;
        text-align: center;
        font-size: 18px;
        margin-top: 20px;
        height: 100%;
        position: absolute;
        .background-color(white, .8);
        top: -20px;
        padding-bottom: 20px;
        padding-top: 20px;
        margin-left: -15px;
    }

    .trial-not-found-container {
        width: 100%;
        text-align: center;
        font-size: 18px;
        margin-top: 20px;
        height: 100%;
        position: absolute;
        top: -20px;
        padding-bottom: 20px;
        padding-top: 20px;
        margin-left: -15px;

        i {
            color: @brand-danger;
        }

        p {
            font-size: 15px;
            margin-top: 15px;
        }
    }
}


.payouts {

    .table {
        background: none;

        th {
            padding-left: 20px;
        }

        td {
            border: none;
            padding-bottom: 0;
        }
    }

    .remove-payout-button {
        margin-top: 2px;

        > i {
            color: @brand-danger;
        }
    }
}

.status {

    .remove-status-button {
        margin-top: 2px;

        > i {
            color: @brand-danger;
        }
    }
}

.trial-update .modal-dialog {
    width: 700px;
}
