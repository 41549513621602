app-root {
    display: flex;
    flex-direction: column;
    height: 100%;

    mat-sidenav-container {
        flex: 1 1 auto;
    }

    #page-content-with-footer {
        display: flex;
        flex-direction: column;
        height: 100%;

        #page-content-container {
            flex-grow: 1;
        }
    }
}

corteva-footer {
    margin-top: 15px;
}