
.quickLink-padding {
    padding-left: 5px;
    padding-right: 5px;
}

.quickLinks-no-scroll {
    overflow-y: hidden;
}

.quickLinksrows {
    > mat-list-item {
        line-height: 16px;

        &:nth-child(even) {
            background-color: @corteva-white;
        }

        &:nth-child(odd) {
            background-color: @blue-lighter;
        }
    }
}

.related-line-item-container {
    font-size: 14px;
    display: flex;
}


// make the scrolling happen within the tabs, so you can see the tab headers
// for example if there are a lot of favorite records, you can still see you are in favorites

mat-tab-group.scroll-inside {
    max-height: 100%;

    .mat-tab-body-wrapper {
        overflow: auto;
        max-height: 100%;
    }
}