@import (reference) "ftms.less";

//== Base Elements

html {
    position: relative;
    min-height: 100%;
}

body {
    background-color: white;
    #gradient > .vertical(white; @gray-lightest);
    background-position-y: 91px;
    // !important because bootstrap tries to adding padding inline when
    // modals are shown, causing a strange jumping behavior
    padding: 0 !important;
}

option {
    padding: 0;
}

select::-ms-expand {
    width: 13px;
    height: 13px;
    color: transparent;
    background:
    linear-gradient(65deg, white 50%, transparent 50%),
    linear-gradient(115deg, transparent 50%, white 50%),
    linear-gradient(to right, @gray, @gray);
    background-position-x: 4px;
    background-position-y: 4px;
    background-repeat: no-repeat;
    border: none;
}

.unselectable {
   -moz-user-select: -moz-none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

.cleared {
    clear: both !important;
    float: none !important;
}

.pull-up {
    margin-top: -16px;
    min-height: 17px;
}

.no-break {
    white-space: nowrap;
}

.has-error .form-control, 
input.has-error,
.has-error .select2 .selection > * {
    background: @state-danger-bg;
}

.has-warning .form-control, .has-warning .select2 .selection > * {
    background: @state-warning-bg;
}

.has-error .select2 .selection > * {
    border-color: @state-danger-text;
}

input.has-error {
    border-color: @state-danger-text;
    &:focus {
		border-color: darken(@state-danger-text, 10%);
      @shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten(@state-danger-text, 20%);
      .box-shadow(@shadow);
	}
}

.dropdown-menu > li > a {
    cursor: pointer;

    &.disabled {
        background: none;
        cursor: default;
        color: #BBB;
        font-style: italic;
    }
}


.old-browser-alert {
    width: 100%;
    display: block;
    position: relative;
}

.cmdSymbol {
    font-size: 1.35em;
    float: left;
    margin-top: -4px;
}

.detail-section {
    margin-bottom: 70px;
}

.ang-detail-section {
    margin-bottom: 50px;
}

p.pre {
    white-space: pre-wrap;
}

.modal-backdrop {
    position: fixed;
}

.error-modal {
    .modal-header {
        background: @brand-danger;
        color: white;
        font-weight: bold;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
}

.moveable {
    cursor: move;
}

// stolen from stackoverflow
kbd {
    color: #000;
    background: #eee;
    border-style: solid;
    border-color: #ccc #aaa #888 #bbb;
    padding: .1em .6em;
    border: 1px solid #ccc;
    font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;
    background-color: #f7f7f7;
    color: #333;
    -moz-box-shadow: 0 1px 0 rgba(0,0,0,0.2),0 0 0 2px #fff inset;
    -webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.2),0 0 0 2px #fff inset;
    box-shadow: 0 1px 0 rgba(0,0,0,0.2),0 0 0 2px #fff inset;
    border-radius: 3px;
    display: inline-block;
    margin: 0 .1em;
    text-shadow: 0 1px 0 #fff;
    line-height: 1.4;
    white-space: nowrap;
}

.came-from-search .show-if-from-search {
    visibility: visible;
}

.show-if-from-search {
    // We have to use the visibility property instead of display: none, because
    // the visibility property keeps the space taken up by the element in the page.
    // Without that, then the side-nav gets moved up, and overlaps the floating header bar
    visibility: hidden;
}

.search-criteria-margin {
    margin-bottom: 20px;
}

.auto-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
}