@import (reference) "ftms.less";
@import (reference) "_utility.less";
@import (reference) "_variables.less";

.trial-detail {
    .trial-title-form-item {
        > div {
            float: left;
            margin-top: 7px;
        }
    }
}

.popover.bottom.download-trials-popover {
    margin-top: 2px;
    padding-bottom: 10px;
    width: 230px;
    margin-left: -51px;

    hr {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    p {
        font-weight: bold;
        margin-bottom: 5px;
    }

    label {
        font-weight: normal;
        margin-right: 10px;
    }

    button {
        margin-top: 10px;

        &.download-button {
            background: #337ab7;
            border-color: darken(#337ab7, 10%);
        }
    }
}

table#assignments-table {
    > thead > tr {
        > th:first-child {
            padding-left: 10px;
        }
    }

    > tbody > tr {
        &.requested {
            background-color: @table-bg-alt;

            &.selected {
                background-color: darken(@table-bg-alt, 15%);

                &:hover {
                    background-color: darken(@table-bg-alt, 20%);
                }
            }

            &:hover {
                background-color: darken(@table-bg-alt, 7%);
            }

            > td:first-child {
                border-left: 10px solid @green;
                padding-left: 5px;
            }
        }

        > td:first-child {
            padding-left: 10px;
        }
    }
}

#assignmentsPageGrid {
    &.ag-theme-bootstrap.ftms-ag-grid {

        .ag-request {
            background-color: @table-bg-alt;

            &.ag-row-selected {
                background-color: darken(@table-bg-alt, 15%);

                &.ag-row-hover {
                    background-color: darken(@table-bg-alt, 20%);
                }
            }

            &.ag-row-hover {
                background-color: darken(@table-bg-alt, 7%);
            }
        }

        .ag-pinned-left-cols-container {
            .ag-row {
                .ag-cell:first-child {
                    padding-left: 10px;
                }
            }

            .ag-row.ag-request {

                .ag-cell:first-child {
                    // Not sure why this seems to be doubled compared to the legacy grid, which was
                    // set to 10px, but 5px here looks the same.
                    border-left: 5px solid @green;
                    padding-left: 5px;
                }
            }
        }

        .ag-row-odd.ag-request {
            background-color: @table-bg-alt-accent;

            &.ag-row-selected {
                background-color: darken(@table-bg-alt, 15%);

                &.ag-row-hover {
                    background-color: darken(@table-bg-alt, 20%);
                }
            }

            &.ag-row-hover {
                background-color: darken(@table-bg-alt, 7%);
            }
        }
    }
}

table#assignments-table.table-striped > tbody > tr.requested:nth-child(odd) {
    background-color: @table-bg-alt-accent;

    &.selected {
        background-color: darken(@table-bg-alt, 15%);

        &:hover {
            background-color: darken(@table-bg-alt, 20%);
        }
    }

    &:hover {
        background-color: darken(@table-bg-alt, 7%);
    }
}

.excel-import-modal {
    a {
        cursor: pointer;
    }

    .is-working-container {
        margin-top: 15px;
        margin-left: 15px;
    }

    .error-message-container {
        background: #fbe9e9;
        border: 2px solid #f7a4a4;
        border-radius: 4px;
        padding: 10px;
        padding-bottom: 0;

        table, thead, tbody, tr, td {
            border: none;
            background: none;
            vertical-align: top;
        }

        table {
            margin-bottom: 10px;
            margin-left: 30px;

            ul {
                margin: 0;
                padding: 0;
            }
        }

        #validation-error-title {
            margin-bottom: 10px;
            font-weight: bold;
        }
    }

    .input-container {
        width: 100%;

        form, input {
            width: 100%;
        }
    }

    table {
        border-bottom: 1px solid #d2c5bf;
        border-left: 1px solid #d2c5bf;
        border-right: 1px solid #d2c5bf;

        thead {
            tr {
                background: white;
            }
        }

        tbody {
            tr {
                td.location-column {
                    white-space: nowrap;

                    &.location-column-1 {
                        padding-right: 2px;
                    }

                    &.location-column-2 {
                        padding-left: 2px;
                    }
                }
            }
        }
    }
}

@media(min-width: @screen-md-min) {
    .right-group {
        float: right;
    }
}

@media(max-width: @screen-sm-max) {
    .right-group {
        float: left;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.trial-search-grid {
    .mat-table {
        .mat-column-TrialNumber {
            width: 202px;
            min-width: 202px;
        }

        .mat-column-TrialYear {
            width: 76.8px;
        }

        .mat-column-Ded {
            max-width: 120px;
        }

        .mat-column-Cu {
            max-width: 80px;
        }

        .mat-column-CountryName {
            max-width: 90px;
        }

        .mat-column-SubdivisionName {
            max-width: 80px;
        }

        .mat-column-Facility {
            max-width: 75px;
        }

        .mat-column-Status {
            max-width: 120px;
        }

        .mat-column-Crops, .mat-column-Pests {
            max-width: 100px;
        }

        .mat-column-Contractor {
            max-width: 130px;
        }

        .mat-column-CreatedDate, .mat-column-UpdatedDate {
            max-width: 90px;
        }
    }
}