quote-edit-modal-line-item-assoc-grid {
  .quote-grid-document {
    background-color: #bbb;

    .quote-grid-document-ui {
      display: flex;
    }
  }

  .quote-grid-document-ui {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0.9;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    z-index:101;
  }
}
