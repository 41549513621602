.news-loading-container {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 18px;
}

.news-hyperlink-disabled {
    &:hover{
        text-decoration:none;
    }
}