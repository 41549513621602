.responsibility-report-table {

	th.mat-header-cell {
		border-left: 1px solid #CCC;
		border-right: 1px solid #CCC;
		text-align: center;
		padding: 0 !important;
	}

	tr.top-header > th {
		border-bottom: none;
		background-color: rgb(231, 231, 231);
	}

	tr.bottom-header {
		background-color: rgb(243, 243, 243);
	}

	td {
		border-left: 1px solid #CCC;
		border-right: 1px solid #CCC;
		text-align: right;
	}
}
