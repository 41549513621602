@import (reference) "../_material-design-overrides.less";

ibp-trial-grid {
    overflow: auto;
    display: block;

    .mat-table {

        .mat-cell, .mat-header-cell, .mat-footer-cell {
            padding: 5px;
        }

        tr.mat-header-row {
            height: 25px;
        }

        tr.mat-row {
            height: 25px;
        }
    }

    table.mat-table {
        border-collapse: separate;
    }

    .mat-header-cell {
        // Not sure why, but the sticky headers don't scroll properly if word wrapping is enabled
        white-space: nowrap;
    }
}