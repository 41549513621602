@import (reference) "_variables.less";

.card-container {
    padding: 5px;
    padding-bottom: 10px;

    .card {
        background: white;
        box-shadow: 0 1px 5px rgba(0,0,0,.15);
        border-radius: 3px;
        padding: 10px;
        border: 1px solid #DDD;

        &.trial-card {
            cursor: pointer;

            &:hover {
                background: #f5f5f5;
            }
        }

        > span {
            white-space: nowrap;
        }

        .divider {
            margin-left: 6px;
            margin-right: 6px;
            color: #AAA;
        }

        .card-comments {
            display: block;
            margin-top: 10px;
        }

        hr {
            margin-top: 5px;
            margin-bottom: -5px;
        }

        .card-title {
            color: @link-color;
        }

        .comma-separator {
            margin-left: -2px;
        }
    }

    a {
        cursor: pointer;
        text-decoration: none !important;
        color: black;
    }
}
