
.box-badge {
    font-size: 12px;
    line-height: 20px;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 20px;
    text-align: center;

    border-radius: 4px;
    background-color: #ccc;

    // Use the Bootstrap contextual color classes for color
    &.box-badge-success {
        .bg-success;
    }

    &.box-badge-warning {
        .bg-warning;
    }

    &.box-badge-danger {
        .bg-danger;
    }
}
